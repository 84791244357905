import React from "react";
import { ParallaxProvider } from "react-scroll-parallax";
import Navbar from "../components/Navbar";
import CommunitySection from "../components/page-sections/CommunitySection";
import ElevateSection from "../components/page-sections/ElevateSection";
import SustainabilitySection from "../components/page-sections/SustainabilitySection";
import SharingSection from "../components/page-sections/SharingSection";
import ParallaxBackground from "../components/page-sections/ParallaxBackground";
import FingertipsSection from "../components/page-sections/FingertipsComponent";


export default function Home() {
  return (
    <div style={{ overflowX: 'hidden' }}>
      <ParallaxProvider>
        <Navbar />
        <ParallaxBackground />
        <SharingSection
          title="Unlock the Power of Sharing"
          subtitle="Transform your excess tools and equipment into assets while accessing a diverse range of resources for your projects. Tooli connects average people, creators, DIY enthusiasts, and professionals in a revolutionary platform."
          listItems={[
            { title: "Monetize Clutter", description: "Earn income by renting out tools and equipment that are sitting idle in your space." },
            { title: "Access On-Demand", description: "Find the tools and services you need for your project without the need for expensive purchases." },
          ]}
          buttonText="Get Started"
        />
        <ElevateSection
          title="Elevate Your Projects with Tooli"
          subtitle="Whether you're a DIY enthusiast or a skilled professional, Tooli offers a platform for seamless transactions and connections"
          items={[
            { title: "Flexible Rentals:", description: "Rent tools and equipment on your terms, with a diverse range of options." },
            { title: "Professional Services:", description: "Connect with skilled professionals for specialized projects. Market your services to a targeted audience." },
            { title: "Effortless Transactions:", description: "Secure and transparent transactions with built-in scheduling and management systems." },
          ]}
          buttonText="Join Now"
          learnMoreText="Learn More"
        />
        <SustainabilitySection
          mainTitle="Environmentally Focused, Reducing Waste."
          subTitle="Tooli's mission is to reduce environmental impact and offer a centralized platform for renting tools and services. Join us in turning excess tools into opportunities."
          firstContentTitle="Sustainable Renting"
          firstContentText="Extend the lifespan of tools, reduce waste, and contribute to a more sustainable approach to tooling and equipment usage."
          secondContentTitle="Centralized Marketplace"
          secondContentText="Discover tools, equipment, and services in one place. No more unnecessary purchases, just access what you need when you need it."
        />
        <CommunitySection
          imageWidth="45vw"
          backgroundColor="#3975B1"
          title="Growing Struggling Communities"
          subtitle="Tooli is committed to making a positive impact. Help us support our communities by donating tools, providing tool libraries, and offering educational opportunities. Together, we can make a difference."
          buttonText="Learn More"
        />
        <FingertipsSection
          textColor='#123E64'
          backgroundColor="#FEFEFE"
          image="/TractorImage.png"
          title="Everything You Need, Right At Your Fingertips!"
          subtitle="Tooli is your go-to platform for renting tools and services. Revolutionize the way you approach projects with our comprehensive marketplace."
          buttonText="Get Started Now"
        />

      </ParallaxProvider>
    </div>
  );
}
