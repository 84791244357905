import React from "react";
import {
  List,
  ListItem,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

const styles = {

    root: {
      width: "100%",
      display: 'flex',
      alignItems: 'center',
      background: '#FEFAF0',
    },
    image: {
      paddingRight: '2vw',
      height: '40px', // Default font size for larger screens (lg)
        '@media (max-width: 1200px)': {
          height: '30px', // Adjust for medium screens (md)
        },
        '@media (max-width: 992px)': {
          height: '25px', // Adjust for small screens (sm)
        },
        '@media (max-width: 768px)': {
          height: '20px', // Adjust for extra-small screens (xs)
        },
    },
    content: {
      flex: 1,
    },
    header: {
      fontWeight: 'bold',
      fontSize: '40px', // Default font size for larger screens (lg)
        '@media (max-width: 1200px)': {
          fontSize: '30px', // Adjust for medium screens (md)
        },
        '@media (max-width: 992px)': {
          fontSize: '25px', // Adjust for small screens (sm)
        },
        '@media (max-width: 768px)': {
          fontSize: '20px', // Adjust for extra-small screens (xs)
        },
    },
    subheader: {
      marginTop: '16px', // Assuming theme.spacing(2) corresponds to 16px
      marginBottom: '32px', // Assuming theme.spacing(4) corresponds to 32px
      fontSize: '18px', // Default font size for larger screens (lg)
      maxWidth: '90%',
      minWidth: '300px',
        '@media (max-width: 1200px)': {
          fontSize: '16px', // Adjust for medium screens (md)
        },
        '@media (max-width: 992px)': {
          fontSize: '14px', // Adjust for small screens (sm)
        },
        '@media (max-width: 768px)': {
          fontSize: '12px', // Adjust for extra-small screens (xs)
        },
    },
    listItem: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: '8px', // Assuming theme.spacing(1) corresponds to 8px
    },
    listButtons: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-evenly',
    },
    checkIcon: {
      marginRight: '8px', // Assuming theme.spacing(1) corresponds to 8px
      color: '#FF4848', // Replace with your desired color
    },
  };

  function PlatformSection({items, image, backgroundColor}) {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md')); // Adjust breakpoint as needed
    return (
      <div style={{ minHeight: '85vh', padding: '5vh', backgroundColor: backgroundColor }}>
        <div style={{
          ...styles.root,
          backgroundColor: backgroundColor,
        }}>
          {!isMobile && (
            <img
            src={image}
            alt="Tooli"
            style={{ width: '35vw', paddingRight: '20px' }}
          />
          )}
          <div style={{ ...styles.content, paddingRight: '5vw' }}>
            <List>
              {items.map((item, index) => (
                <ListItem key={index} style={styles.listItem}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div>
                      <Typography variant="h2" sx={{...styles.header, color: '#123E64', fontWeight: '1000', display: 'flex', alignItems: 'center' }}>
                        <img src = {item.src}  alt="Tooli"
                        style={{paddingRight: '2vw',
                            height: '40px', // Default font size for larger screens (lg)
                            '@media (max-width: 1200px)': {
                              height: '30px', // Adjust for medium screens (md)
                            },
                            '@media (max-width: 992px)': {
                              height: '25px', // Adjust for small screens (sm)
                            },
                            '@media (max-width: 768px)': {
                              height: '20px', // Adjust for extra-small screens (xs)
                            },}}
                        />
                        {item.title}
                      </Typography>
                      <Typography variant="body1" sx={{...styles.subheader, color: '#123E64', paddingLeft: '90px', paddingBottom: '50px' }}>
                        {item.description}
                      </Typography>
                    </div>
                  </div>
                </ListItem>
              ))}
            </List>
          </div>
      </div>
    </div>
    );
  }

  export default PlatformSection;