import React from "react";
import Button from "@mui/material/Button";
import {
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

const styles = {

    root: {
      width: "100%",
      display: 'flex',
      alignItems: 'center',
      background: '#FEFAF0',
    },
    image: {
      width: '30vw',
      paddingLeft: '15vw',
      paddingRight: '5vw',
      height: 'auto',
    },
    content: {
      flex: 1,
    },
    header: {
      fontWeight: '1000',
      fontSize: '60px', // Default font size for larger screens (lg)
        '@media (max-width: 1200px)': {
          fontSize: '50px', // Adjust for medium screens (md)
        },
        '@media (max-width: 992px)': {
          fontSize: '40px', // Adjust for small screens (sm)
        },
        '@media (max-width: 768px)': {
          fontSize: '30px', // Adjust for extra-small screens (xs)
        },
    },
    subheader: {
      marginTop: '16px', // Assuming theme.spacing(2) corresponds to 16px
      marginBottom: '32px', // Assuming theme.spacing(4) corresponds to 32px
      fontSize: '18px', // Default font size for larger screens (lg)
        '@media (max-width: 1200px)': {
          fontSize: '16px', // Adjust for medium screens (md)
        },
        '@media (max-width: 992px)': {
          fontSize: '14px', // Adjust for small screens (sm)
        },
        '@media (max-width: 768px)': {
          fontSize: '12px', // Adjust for extra-small screens (xs)
        },
    },
  };

  function CommunitySection({ title, subtitle, buttonText, backgroundColor, image, imageWidth, titleColor, subtitleColor }) {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md')); // Adjust breakpoint as needed
    return (
      <div>
        <div 
        style={{
          ...styles.root,
          backgroundColor: backgroundColor,
          overflow: 'hidden',
          minHeight: '75vh',
          maxWidth: '100vw',
          flexDirection: isMobile ? "column" : "row",
        }}>
          {isMobile && (
          <img
          src={image || "/VillageImage.png"}
          alt="village"
            style={{
              ...styles.image,
              width: '80%',
              height: "auto",
              order: 0,
            }}
          />
        )}
          {!isMobile && (
            <img
            src={image || "/VillageImage.png"}
            alt="village"
            style={{ ...styles.image, width: imageWidth, paddingLeft: '5vw' }}
            />
          )}
        <div style={{ ...styles.content, maxWidth: '50%', paddingRight: '20px' }}>
          <Typography variant="h1" color={titleColor || "white" }sx={{ ...styles.header, paddingBottom: '5vw' }}>
            {title}
          </Typography>
          <Typography variant="body1" color={subtitleColor || "white"} sx={{ ...styles.subheader, paddingBottom: '5vh' }}>
            {subtitle}
          </Typography>
          <Button
            color='inherit'
            variant='outlined'
            style={{
              backgroundColor: subtitleColor,
              color: backgroundColor,
              fontWeight: 'bold',
              textTransform: 'uppercase',
              height: '50px',
              width: '30%',
              minWidth: '200px',
              borderRadius: '8px',
              borderWidth: '1px',
            }}
            onClick={() => {
              // Handle sign-up button click if needed
            }}
          >
            {buttonText}
          </Button>
        </div>
        </div>
      </div>
    );
  }

  export default CommunitySection;