import React from "react";
import { ParallaxProvider } from "react-scroll-parallax";
import Navbar from "../components/Navbar";
import FingertipsSection from "../components/page-sections/FingertipsComponent";
import EarningsSection from "../components/page-sections/EarningsSection";
import PricingSection from "../components/page-sections/PricingSection";
import QuestionSection from "../components/page-sections/QuestionSection";
import CommunitySection from "../components/page-sections/CommunitySection";

const indexedTextEntries = ['Comprehensive Marketplace', 'User-Friendly Listing Process', 'Detailed Tooling Uses', 'Precision Search Algorithms', 'Secure Transaction System', 'Rating and Review System', 'Environmental Responsibility', 'Social Impact', 'Governance Values'];
const ListEntries = [
  { header: 'Unlock Free Earnings', content: 'List your tools and equipment on Tooli and turn clutter into income. Our platform offers a comprehensive marketplace for users to discover and rent tools and services.' },
  { header: 'User-Friendly Listings', content: "Easily create detailed listings with images, descriptions, and rental terms. Tooli's platform ensures a straightforward process for both listers and renters." },
  { header: 'Detailed Tooling Uses', content: 'Specify all use cases for your tools, helping renters find exactly what they need. Target specific renter markets with in-depth tool information.' },
  { header: 'Secure Transaction System', content: 'Tooli incorporates a secure and transparent transaction system, ensuring smooth rental processes and secure payment transactions for both tool owners and renters.' },
  { header: 'Rating and Review System', content: 'Build trust within the Tooli community. Users can leave feedback based on their experiences, providing valuable insights for future transactions.' },
];
const accordionItems = [
  { title: 'How do I list my tools, equipment, or services on Tooli?', content: 'To list your items or services on Tooli, navigate to your dashboard and find the "Listings" section. Follow the user-friendly process to create detailed listings with images, descriptions, and rental terms. For more guidance, check our comprehensive guide in the Help Center.' },
  { title: 'What is Tooli’s pricing structure for listers?', content: 'Tooli offers a transparent pricing structure for listers. Currently, there is a standard plan with no monthly fees, allowing you to list your items without incurring additional costs. Learn more about our pricing in the Pricing Section.' },
  { title: 'How does Tooli ensure secure transactions between listers and renters?', content: 'Tooli incorporates a secure transaction system using Stripe to facilitate secure payment transactions. Your earnings are directly deposited into your account after successful transactions, ensuring a smooth and secure process for both listers and renters.' },
  { title: 'Can I offer specialized tools or services on Tooli?', content: 'Absolutely! Tooli is designed to cater to a diverse range of tools and services. Whether you have specialized equipment or offer unique services, you can create detailed listings to attract renters with specific needs. Make sure to provide comprehensive information about your offerings.' },
  { title: 'What steps can I take to maximize visibility for my listings?', content: 'To maximize visibility for your listings, consider adding detailed tooling uses, high-quality images, and accurate descriptions. Utilize the precision search algorithms on Tooli by providing relevant keywords and tags. Additionally, check out our tips in the Help Center on optimizing your listings.' },
]

export default function ListingPage() {
  return (
    <ParallaxProvider>
      <Navbar />
      <div style={{height: '10vh'}}/>
      <FingertipsSection
        backgroundColor="#FEFEFE"
        textColor='#123E64'
        fontWeight='1000'
        image="/MessyGarageImage.png"
        title="Monetize Your Clutter"
        subtitle="List your tools, equipment, or services on Tooli's platform and turn your unused items into a source of income. Join Tooli's comprehensive marketplace and connect with renters in need of your resources."
        buttonText="Get Started"
      />
      <EarningsSection
        titleText="Tooli Listings"
        subtitleText="Maximize Your Earnings Potential."
      />
      <PricingSection
        price="$0 / month"
        elitePlanText1="Standard User Plan"
        elitePlanText2="List with Ease, Earn with Confidence."
        indexedTextEntries={indexedTextEntries}
        ListEntries={ListEntries}
      />
      <CommunitySection
          subtitleColor="white"
          imageWidth = "50vh"
          image = "/MoneyImage.png"
          backgroundColor="#123E64"
          title="Monetize Your Listings"
          subtitle="Listing on Tooli isn't just about tools; it's about unlocking a new source of income. Embrace a platform that values your listings, providing steady visibility, comprehensive support, and transparent pay structures."
          buttonText="Learn More"
      />
      <QuestionSection
        accordionItems={accordionItems}
        mainTitle="Questions"
        subTitle="Find answers to common questions and concerns about listing your tools, equipment, or services on Tooli."
      />
    </ParallaxProvider>
  );
}

