import React, { useEffect} from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation, Navigate } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { createTheme } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import Home from './pages/Home';
import ListingPage from './pages/ListingPage';
import Footer from './components/Footer';
import TooliToolbar from './components/TooliToolbar';
import ServicesPage from './pages/ServicesPage';
import Navbar from './components/Navbar';
import RentingPage from './pages/RentingPage';
import AboutPage from './pages/AboutPage';



const lightTheme = createTheme({
  palette: {
    background: {
      default: '#F1F4F8',
    },
    text: {
      primary: '#14181b',
    },
    primary: {
      main: '#555555',
    },
    secondary: {
      main: '#333333',
    },
  },
  typography: {
    fontFamily: 'Lato',
    h1: {
      fontWeight: 700, // Bold weight for headers
      fontSize: '2.5rem', // example size, adjust as needed
      fontStretch: 'normal', // Adjust based on your requirements
    },
    h2: {
      fontWeight: 600,
      fontSize: '2rem',
      fontStretch: 'normal',
    },
    h3: {
      fontWeight: 600,
      fontSize: '1.75rem',
      fontStretch: 'normal',
    },
    h4: {
      fontWeight: 500,
      fontSize: '1.5rem',
      fontStretch: 'normal',
    },
    h5: {
      fontWeight: 500,
      fontSize: '1.25rem',
      fontStretch: 'normal',
    },
    h6: {
      fontWeight: 500,
      fontSize: '1rem',
      fontStretch: 'normal',
    },
    body1: {
      fontWeight: 400, // Normal weight for body text
      fontSize: '1rem',
      fontStretch: 'normal',
    },
    body2: {
      fontWeight: 400,
      fontSize: '0.875rem',
      fontStretch: 'normal',
    },
    // ... You can continue to customize other typography variants as necessary
  },
});

function Pages() {
  const location = useLocation();

  useEffect(() => {
    // Reset scroll position to the top on route change
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <div className="hideOverflow">
      {<Navbar />}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/listing" element={<ListingPage />} />
        <Route path='/renting' element={<RentingPage />} />
        <Route path="/services" element ={<ServicesPage />} />
        <Route path="/about" element ={<AboutPage />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
      {<Footer />} 
      {<TooliToolbar />} 
    </div>
  );
}

function App() {

  return (
    <ThemeProvider theme={lightTheme}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Router>
          <Pages />
        </Router>
      </LocalizationProvider>
    </ThemeProvider>
  );
}

export default App;