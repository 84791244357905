import React from "react";
import { ParallaxProvider } from "react-scroll-parallax";
import Navbar from "../components/Navbar";
import FingertipsSection from "../components/page-sections/FingertipsComponent";
import EarningsSection from "../components/page-sections/EarningsSection";
import QuestionSection from "../components/page-sections/QuestionSection";
import CommunitySection from "../components/page-sections/CommunitySection";
import SustainabilitySection from "../components/page-sections/SustainabilitySection";
import ElevateSection from "../components/page-sections/ElevateSection";
import {Divider} from "@mui/material";

const accordionItems = [
  { title: 'How do I list my professional services on Tooli?', content: 'To showcase your expertise on Tooli, go to your dashboard and find the "Listings" section. Follow our intuitive process to create detailed service listings, including images, descriptions, and pricing. Refer to our comprehensive guide in the Help Center for step-by-step assistance.' },
  { title: 'What is Tooli’s pricing structure for service providers?', content: 'Tooli adopts a transparent pricing model for service providers. Currently, there is a standard plan with no monthly fees, allowing you to list your services without additional costs. Learn more about our service pricing in the Pricing Section.' },
  { title: 'How does Tooli ensure secure transactions between service providers and clients?', content: 'Tooli employs a secure transaction system, utilizing Stripe for safe and seamless payment transactions. Your earnings are directly deposited into your account after successful transactions, ensuring a smooth and secure process for both service providers and clients.' },
  { title: 'Can I offer specialized services on Tooli?', content: 'Absolutely! Tooli caters to a diverse range of professional services. Whether you have specialized skills or offer unique expertise, you can create detailed listings to attract clients with specific needs. Provide comprehensive information about your services to stand out.' },
  { title: 'What steps can I take to maximize visibility for my service listings?', content: 'To enhance visibility for your service listings, consider adding detailed descriptions, high-quality images, and accurate pricing. Optimize your listings by using relevant keywords and tags to take advantage of our precision search algorithms. Explore additional tips in the Help Center for optimizing your service listings.' },
];

export default function ServicesPage() {
  return (
    <ParallaxProvider>
      <Navbar />
      <div style={{ height: '10vh' }} />
      <SustainabilitySection
        mainTitle="Empower Your Professional Journey with Tooli."
        subTitle="Tooli's mission is to empower professionals like you by providing a centralized platform to showcase and monetize your unique skills and services. Join us in turning your expertise into opportunities."
        firstContentTitle="Diverse Professional Services"
        firstContentText="Offer a wide range of professional services and connect with clients in need of your expertise. Tooli provides a platform for you to showcase your skills to a broad audience."
        secondContentTitle="Transparent Pricing"
        secondContentText="Utilize Tooli's transparent pricing structure. Our standard plan has no monthly fees, allowing you to list your services without incurring additional costs. Earn what you deserve with Tooli."
      />
      <ElevateSection
        title="Elevate Your Professional Profile"
        subtitle="Whether you're a seasoned professional or just starting, Tooli offers a platform for seamless transactions and connections."
        items={[
          { title: "Diverse Service Offerings:", description: "List a variety of services, from specialized skills to general expertise, and attract clients looking for your unique talents." },
          { title: "Transparent Transactions:", description: "Ensure secure and transparent transactions with built-in scheduling and management systems. Tooli prioritizes a seamless experience for both service providers and clients." },
          { title: "Optimized Visibility:", description: "Maximize visibility for your services by creating detailed listings. Utilize keywords and tags to enhance discoverability and stand out in search results." },
        ]}
      />
      <Divider style={{ height: '2px' }} />
      <FingertipsSection
        backgroundColor="#123E64"
        textColor="#FEFEFE"
        fontWeight="800"
        image="/shakingHands.png"
        title="Monetize Your Professional Skills"
        subtitle="List your professional services on Tooli's platform and turn your expertise into a source of income. Join Tooli's comprehensive marketplace and connect with clients in need of your unique skills."
        buttonText="Get Started"
      />
      <CommunitySection
        imageWidth="50vh"
        image="/MoneyImage.png"
        backgroundColor="#FEFAF0"
        titleColor="#123E64"
        subtitleColor="#123E64"
        title="Grow Your Business"
        subtitle="Listing on Tooli is a gateway to unlocking a new source of clients. Embrace a platform that values your professional services, providing steady visibility, comprehensive support, and transparent pay structures."
        buttonText="Download"
      />
      <EarningsSection
        titleText="Tooli Services"
        subtitleText="Maximize Your Earnings Potential as a Service Provider."
      />
      <QuestionSection
        accordionItems={accordionItems}
        mainTitle="Questions"
        subTitle="Find answers to common questions and concerns about listing your professional services on Tooli."
      />
    </ParallaxProvider>
  );
}

