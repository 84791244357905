import React from "react";
import { ParallaxProvider} from "react-scroll-parallax";
import Navbar from "../components/Navbar";
import FingertipsSection from "../components/page-sections/FingertipsComponent";
import AboutSection from "../components/page-sections/AboutSection";
import EmployeeSection from "../components/page-sections/EmployeesSection";
import PlatformSection from "../components/page-sections/PlatformSection";


const cardData = [
  {
    iconImage: '/profile-image-03.png',
    header: 'Elliot Eyre',
    subheader: 'Co-Founder, CEO',
    body: 'Elliot is an entrepreneur and software developer from Northern Virginia. Passionate about community involvement and peer-to-peer commerce, Elliot used his coding and business experience to develop the Tooli app.',
  },
  {
    iconImage: '/profile-image-02.png',
    header: 'Chris Eyre',
    subheader: 'Co-Founder, CFO',
    body: 'Chris has been a mortgage broker for almost 20 years, with financial experience across multiple industries. His passion as a wood worker and homesteader gave him the inspiration for Tooli.',
  },{
    iconImage: '/profile-image-01.png',
    header: 'Jim Wallace',
    subheader: 'COO',
    body: 'With 27 years of Executive experience at Fortune 500 companies like Unisys, Salesforce, Nuance Communications, and Pegasystems, Jim is an expert in growing and scaling global organizations.',
  },{
    header: 'Jason Poffenberger',
    subheader: 'CTO',
    body: 'As a leading Mobile Applications Developer for the National Institute of Standards and Technology (NIST), Jason has designed, developed, and deployed applications to solve both industry and government issues.',
  },{
    iconImage: '/profile-image-04.png',
    header: 'Dan Kolansky',
    subheader: 'CIO',
    body: 'A web and software developer specializing in Flutter development, Dan has spent over a decade providing quality software solutions to businesses in Northern Virginia.',
  }
];


export default function AboutPage() {
  return (
    <ParallaxProvider>
      <Navbar />
      <div style={{height: '20vh', backgroundColor: "#123E64" }}/>
      <FingertipsSection
        backgroundColor="#123E64"
        textColor="#FEFEFE"
        fontWeight="800"
        image="/shakingHands.png"
        title="Building a Community with Shared Tools"
        subtitle="At Tooli, our mission is to connect individuals, tools, and services in a seamless and mutually beneficial way. We believe in empowering professionals, promoting sustainability, and providing convenience for our valued users."
        buttonText="Get Started"
      />
      <AboutSection
        headerColor="#FEFEFE"
        backgroundColor="#123E64"
        mainLineColor="#FEFEFE"
        bodyColor="#FEFEFE"
        image="/tooliLogoName.svg"
        mainLine="Profitability First"
        headerText="Our Founders"
        subheaderText="Tooli was created when a group of entrepreneurs recognized a need in the tool rental industry and combined their talents to solve it."
        buttonText="Learn More"
      />
      <EmployeeSection
        titleText="Innovators"
        subtitleText="Meet Our Team"
        cardData={cardData}
      />
      <PlatformSection
        title="Elevate Your Experience with Tooli."
        subtitle="Tooli stands as a testament to what's possible when innovation meets understanding. We've created a realm where professionals don't just succeed but thrive."
        items={[
          { src: "no-poverty-2.svg", title: "Community", description: "We believe in supporting local communities by offering access to tools and services nearby at low cost. By connecting local professionals with users, we foster a sense of community and contribute to local economic growth." },
          { src: "leaf-1.svg", title: "Sustainability", description: "Rooted in sustainability, Tooli offers an alternative to buying new or recycling that reduces overall waste. Our commitment to sustainability goes beyond just tool and service rentals – we actively work towards minimizing environmental impact and promoting eco-friendly practices throughout our operations." },
          { src: "bill-1.svg", title: "Affordability", description: "By offering competitive pricing and transparent fee structures, we aim to make quality tool and service rentals affordable for users while ensuring that listers receive their fair share, contributing to a balanced and thriving ecosystem." },
        ]}
        image="/constructionImage.png"
        buttonText="Get Started"
        learnMoreText="Learn More"
      />
    </ParallaxProvider>
  );
}
