import React, { useState, useEffect } from 'react';
import {
  AppBar,
  Toolbar,
  Button,
  Drawer,
  List,
  ListItemButton,
  ListItemText,
  useMediaQuery,
  useTheme,
  IconButton,
  InputBase,
  Box,
  styled,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import { Link, useLocation } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

const CustomToolbar = styled(Toolbar)({
  justifyContent: 'space-between',  // Adjusted to space between buttons and search bar
  alignItems: 'center',
  height: '100px',
});

const NavButton = styled(Button)({
  margin: '0 24px',
  textDecoration: 'none',
  color: 'inherit',
  textTransform: 'none',
  fontSize: '20px',
  borderRadius: 0,
});

const CurrentNavButton = styled(NavButton)({
  borderBottom: '6px solid #3975B1',
});

const SearchBar = styled('div')({
  display: 'flex',
  alignItems: 'center',
  backgroundColor: 'transparent',
  marginLeft: 'auto',
  marginRight: 'auto',
});

const SearchInput = styled(InputBase)({
  marginLeft: '8px',
  flex: 1,
});


const Navbar = observer(() => {
  const [bgOpacity, setBgOpacity] = useState(0);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
  const location = useLocation();
  const [searchTerm, setSearchTerm] = useState('');
  const visibleSearchbar = false;


  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      let newOpacity = Math.min(.8, currentScrollY * 0.005);
      setBgOpacity(newOpacity);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setIsDrawerOpen(open);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    // Implement Algolia search and update results if needed
  };

  const list = () => (
    <div
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}>
      <List>
        <ListItemButton component={Link} to='/download'>
          <ListItemText primary='Download Our App' />
        </ListItemButton>
        <ListItemButton component={Link} to='/home'>
          <ListItemText primary='Home' />
        </ListItemButton>
        <ListItemButton component={Link} to='/renting'>
          <ListItemText primary='Rent Tools' />
        </ListItemButton>
        <ListItemButton component={Link} to='/listing'>
          <ListItemText primary='List Tools' />
        </ListItemButton>
        <ListItemButton component={Link} to='/services'>
          <ListItemText primary='Services' />
        </ListItemButton>
        <ListItemButton component={Link} to='/about'>
          <ListItemText primary='About' />
        </ListItemButton>
      </List>
    </div>
  );

  return (
  <AppBar
    position="fixed"
    style={{
      backgroundColor: `rgba(255, 255, 255, ${bgOpacity})`,
      color: 'black',
    }}
    elevation={0}>
    <CustomToolbar style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
      <Link to='/home'>
        <img
          src='tooliLogo.svg'
          alt='Logo'
          style={{ height: '40px', display: 'block', marginRight: 'auto' }}
        />
      </Link>
      {isMobile ? (
        <Box style={{display:'flex', flexDirection: 'row'}}>
          <IconButton onClick={toggleDrawer(true)} edge="start" color="inherit" aria-label="menu">
            <MenuIcon />
          </IconButton>
        </Box>
      ) : (
          <Box style={{ marginLeft: 'auto', marginRight: 'auto' }}>
          {['Listing', 'Renting', 'Services', 'About'].map((text, index) => (
            <React.Fragment key={index}>
              {location.pathname.toLowerCase() === `/${text.toLowerCase()}` ? (
                <CurrentNavButton
                  component={Link}
                  to={`/${text.toLowerCase().replace(/\s+/g, '-')}`}
                  style={{ fontWeight: '800', margin: '0 40px' }}>
                  {text}
                </CurrentNavButton>
              ) : (
                <NavButton
                  component={Link}
                  to={`/${text.toLowerCase().replace(/\s+/g, '-')}`}
                  style={{ fontWeight: '800', margin: '0 40px' }}>
                  {text}
                </NavButton>
              )}
            </React.Fragment>
          ))}
        </Box>
      )}
      {!isMobile && visibleSearchbar && (
      <Box style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: '10vw'}}>
          <SearchIcon style={{ marginRight: '8px', color: 'black' }} />
          <SearchBar>
            <SearchInput
              value={searchTerm}
              onChange={handleSearchChange}
              sx={{
                borderBottom: '1px solid black',
                paddingBottom: '5px',
                width: {sm: '200px', md: '300px', lg: '400px'},
              }}
            />
          </SearchBar>
        </Box>
      )}
    </CustomToolbar>

    <Drawer
      anchor="right"
      open={isDrawerOpen}
      onClose={toggleDrawer(false)}
      sx={{
        width: 250,
        '& .MuiDrawer-paper': {
          width: 250,
          boxSizing: 'border-box',
        },
      }}>
      {list()}
    </Drawer>
  </AppBar>
);

});

export default Navbar;
