import React from "react";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";

export default function TooliToolbar() {
  const toolbarStyle = {
    backgroundColor: "#FEFEFE",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "10px 20px",
  };

  const linkStyles = {
    color: "black",
    textDecoration: "none",
    "&:hover": {
      textDecoration: "none",
      filter: "brightness(85%)", // Adjust brightness on hover
    },
  };

  const publicURL = process.env.PUBLIC_URL;

  return (
    <Toolbar sx={toolbarStyle}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Link to='/'>
          <img
            src={`${publicURL}/tooliLogo.svg`}
            alt='Logo'
            style={{ width: '60px', margin: '16px', display: 'block', }}
          />
        </Link>
      </div>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexWrap: "wrap",
          justifyContent: "center",
          flexGrow: 1,
        }}
      >
        {[
          [
            "Terms of Service",
            `${publicURL}/Tooli - Consumer Terms of Service.pdf`,
          ],
          [
            "Privacy Policy",
            `${publicURL}/Tooli Privacy Policy.pdf`,
          ],
          [
            "Do Not Sell or Share My Personal Information",
            "/consumer/personalize",
          ],
        ].map(([text, link]) => (
          <a href={link} sx={linkStyles} key={text} download style={{'textDecoration': 'none'}}>
            <Typography
              variant="body1"
              sx={{
                color: "black",
                flexGrow: 1,
                paddingRight: 2,
                paddingLeft: 1,
                fontSize: '14px', // Default font size for larger screens (lg)
                  '@media (max-width: 1200px)': {
                    fontSize: '14px', // Adjust for medium screens (md)
                  },
                  '@media (max-width: 992px)': {
                    fontSize: '10px', // Adjust for small screens (sm)
                  },
                  '@media (max-width: 768px)': {
                    fontSize: '6px', // Adjust for extra-small screens (xs)
                  },
              }}
            >
              {text}
            </Typography>
          </a>
        ))}
      </div>

      <div style={{ display: "flex", alignItems: "center" }}>
        {/* Language Dropdown */}
        <Select
          value="en-US"
          label="Select Language"
          sx={{ marginRight: "10px", backgroundColor: "white", color: "black" }}
        >
          <MenuItem value="en-US">English (US)</MenuItem>
          <MenuItem value="es-US">Español (US)</MenuItem>
          <MenuItem value="en-CA">English (CA)</MenuItem>
          <MenuItem value="fr-CA">Français (CA)</MenuItem>
          <MenuItem value="en-AU">English (AU)</MenuItem>
          <MenuItem value="en-NZ">English (NZ)</MenuItem>
        </Select>

        {/* Social Media Icons */}
        <IconButton href="http://facebook.com/tooli" sx={{ padding: "5px" }}>
          <FacebookIcon style={{ color: "white" }} />
        </IconButton>
        <IconButton href="http://twitter.com/tooli" sx={{ padding: "5px" }}>
          <TwitterIcon style={{ color: "white" }} />
        </IconButton>
        {/* ... Add other social media icons similarly */}
      </div>
    </Toolbar>
  );
}
